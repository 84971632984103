import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { HorizontalGroup, LinkButton, useStyles2, useTheme2 } from '@grafana/ui';
import { LoginForm } from 'app/core/components/Login/LoginForm';
import { InnerBox, LoginLayout } from 'app/core/components/Login/LoginLayout';
import { LoginServiceButtons } from 'app/core/components/Login/LoginServiceButtons';

import { ConfigData } from '../types';
import { configToDto } from '../utils/data';
import { getDefaultBranding } from '../utils/defaultBranding';

export const Preview = ({ data }: { data: ConfigData }) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  const defaultBranding = getDefaultBranding(theme);
  const branding = { ...defaultBranding, ...configToDto(data) };

  /**
   * If the logo has been customised, we hide login title and subtitle, unless
   * they were also customised
   */
  if (branding.loginLogo !== defaultBranding.loginLogo) {
    if (branding.loginTitle === defaultBranding.loginTitle) {
      branding.loginTitle = '';
    }
    if (branding.loginSubtitle === defaultBranding.loginSubtitle) {
      branding.loginSubtitle = '';
    }
  }

  return (
    <div className={styles.container}>
      <LoginLayout branding={branding}>
        <InnerBox>
          <LoginForm onSubmit={() => {}} loginHint={''} passwordHint={''} isLoggingIn={false}>
            <HorizontalGroup justify="flex-end">
              <LinkButton className={''} fill="text" href="#" disabled>
                Forgot your password?
              </LinkButton>
            </HorizontalGroup>
          </LoginForm>
          <LoginServiceButtons />
        </InnerBox>
      </LoginLayout>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      display: flex;
      justify-content: center;
      position: relative;
      width: max(300px, 60%);
      height: 800px;
      margin-left: ${theme.spacing(4)};

      & > div {
        &,
        ::before {
          border-radius: ${theme.shape.borderRadius(4)};
        }
      }
    `,
  };
};
