import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Field, Input } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';

import { AccessControlAction } from '../../types';
import { resourceTypeOptions } from '../constants';
import { OtherSectionData } from '../types';

import { ResourcePicker } from './ResourcePicker';
import { Section } from './Section';

const options = resourceTypeOptions.slice(0, -1);

const fields: Array<{ label: string; description?: string; name: keyof OtherSectionData }> = [
  { label: 'Fav icon', name: 'favIcon', description: 'Appears in the browser tab' },
  { label: 'Menu logo', name: 'menuLogo' },
  { label: 'Loading logo', name: 'loadingLogo' },
  {
    label: 'Android/iOS icon',
    name: 'appleTouchIcon',
    description: 'This icon is shown when you bookmark Grafana in a mobile browser.',
  },
];
export const OtherSection = () => {
  const { register } = useFormContext();
  const canEdit = contextSrv.hasPermission(AccessControlAction.CustomBrandingWrite);
  return (
    <Section label={'Other'} isOpen={false}>
      <Field label={'Application title'} description={'Appears in the browser tab'}>
        <Input {...register('appTitle')} id={'app-title'} disabled={!canEdit} />
      </Field>
      {fields.map((field) => {
        return (
          <Field label={field.label} description={field.description} key={field.name} htmlFor={field.name}>
            <ResourcePicker options={options} name={field.name} />
          </Field>
        );
      })}
    </Section>
  );
};
