import { css } from '@emotion/css';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data/src';
import { FieldSet, Field, Input, Button, ConfirmModal, useStyles2 } from '@grafana/ui';
import { getFooterLinks } from 'app/core/components/Footer/Footer';
import { contextSrv } from 'app/core/services/context_srv';

import { AccessControlAction } from '../../types';
import { FooterData } from '../types';
import { defaultLinksChanged } from '../utils/footerLinks';

import { Section } from './Section';

const defaultFooterLinks = getFooterLinks();

export const FooterSection = () => {
  const { control, register, setValue, watch } = useFormContext<FooterData>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fields: links, append, remove } = useFieldArray({ control, name: 'footerLinks' });
  const styles = useStyles2(getStyles);
  const watchLinks = watch('footerLinks');

  const restoreDefaultLinks = () => {
    setValue('footerLinks', defaultFooterLinks);
    setIsModalOpen(false);
  };

  const canEdit = contextSrv.hasPermission(AccessControlAction.CustomBrandingWrite);
  return (
    <Section label={'Footer'} isOpen={false}>
      {!links.length ? (
        <div className={styles.description}>No links selected. Default links will be displayed.</div>
      ) : (
        links.map((link, index) => {
          return (
            <FieldSet key={link.id}>
              <Field label={'URL'}>
                <Input
                  id={`link-${index}-url`}
                  placeholder={'Enter URL'}
                  defaultValue={link.url}
                  disabled={!canEdit}
                  {...register(`footerLinks.${index}.url`)}
                />
              </Field>
              <Field label={'Link text'}>
                <Input
                  id={`link-${index}-text`}
                  placeholder={'Enter link text'}
                  defaultValue={link.text}
                  disabled={!canEdit}
                  {...register(`footerLinks.${index}.text`)}
                />
              </Field>
              <Button
                variant={'secondary'}
                fill={'outline'}
                icon={'trash-alt'}
                onClick={() => remove(index)}
                className={styles.removeButton}
                disabled={!canEdit}
              >
                Remove link
              </Button>
            </FieldSet>
          );
        })
      )}
      <div className={styles.buttonRow}>
        <Button variant={'secondary'} onClick={() => append({ text: '', url: '' })} disabled={!canEdit}>
          + Add another link
        </Button>
        <br />
        <Button
          icon={'history'}
          variant={'secondary'}
          onClick={() => setIsModalOpen(true)}
          disabled={!defaultLinksChanged(watchLinks, defaultFooterLinks)}
        >
          Restore default links
        </Button>
      </div>
      <ConfirmModal
        title={'Restore defaults'}
        isOpen={isModalOpen}
        body={'Are you sure you want to restore the links to defaults? All changes will be lost.'}
        confirmText={'Yes, restore the defaults'}
        onConfirm={restoreDefaultLinks}
        onDismiss={() => setIsModalOpen(false)}
      />
    </Section>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    buttonRow: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,
    removeButton: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    description: css`
      color: ${theme.colors.text.secondary};
      font-size: ${theme.typography.bodySmall.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
      margin: ${theme.spacing(0.25, 0, 4, 0)};
    `,
  };
};
