import { css } from '@emotion/css';

import { colorManipulator, GrafanaTheme2 } from '@grafana/data';
import { BrandingSettings } from 'app/core/components/Branding/types';

export const getDefaultBranding = (theme?: GrafanaTheme2): BrandingSettings => {
  return {
    appTitle: 'Grafana',
    appleTouchIcon: 'public/img/apple-touch-icon.png',
    favIcon: 'public/img/fav32.png',
    footerLinks: null,
    loadingLogo: 'public/img/grafana_icon.svg',
    loginBackground: css`
      background: url(public/img/g8_login_${theme?.isDark ? 'dark' : 'light'}.svg);
    `,
    loginBoxBackground: theme
      ? css`
          background-color: ${colorManipulator.alpha(theme.colors.background.primary, 0.7)};
        `
      : '',
    loginLogo: 'public/img/grafana_icon.svg',
    loginSubtitle: '',
    loginTitle: 'Welcome to Grafana Enterprise',
    menuLogo: 'public/img/grafana_icon.svg',
  };
};
