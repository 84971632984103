import { IconName } from '@grafana/ui';
import { FooterLink } from 'app/core/components/Footer/Footer';

export const processFooterLinks = (links: FooterLink[]) => {
  const iconMap = new Map<string, IconName>([
    ['documentation', 'document-info'],
    ['community', 'comments-alt'],
    ['support', 'question-circle'],
  ]);

  return links.map((link) => {
    const name = link.text.toLowerCase();
    const footerLink: FooterLink = {
      ...link,
      target: '_blank',
      icon: iconMap.has(name) ? iconMap.get(name) : 'external-link-alt',
    };

    return footerLink;
  });
};

export const defaultLinksChanged = (links: FooterLink[], defaultLinks: FooterLink[]) => {
  if (links.length !== defaultLinks.length) {
    return true;
  }
  const keys: Array<keyof FooterLink> = ['url', 'text'];
  const linksEqual = (link1: FooterLink, link2: FooterLink) => {
    return keys.every((key) => link1[key] === link2[key]);
  };

  return links.some((link, index) => !linksEqual(link, defaultLinks[index]));
};
