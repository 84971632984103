import { css } from '@emotion/css';

import { FooterLink } from 'app/core/components/Footer/Footer';

import { BrandingSettingsDTO, ConfigData } from '../types';

import { getResourcePath, isCSSRule, isFile } from './content';
import { processFooterLinks } from './footerLinks';

const imgElements = ['loginLogo'];
const bgElements = ['loginBackground', 'loginBoxBackground'];

const isFooterLink = (value: FooterLink[] | any): value is FooterLink[] => {
  return value === null || Array.isArray(value);
};

export const configToDto = (config: ConfigData): BrandingSettingsDTO => {
  const settings = Object.entries(config).map(([k, v]) => {
    if (!isFooterLink(v)) {
      if (imgElements.includes(k)) {
        return [k, isFile(v) ? URL.createObjectURL(v) : getResourcePath(v)];
      } else if (bgElements.includes(k)) {
        return [k, getBackground(v, k === bgElements[0])];
      }
      return [k, v];
    } else {
      return [k, processFooterLinks(v)];
    }
  });

  return Object.fromEntries(settings.filter(([_, v]) => !!v));
};

const getBackground = (background: string | File, before?: boolean) => {
  if (!background) {
    return;
  }

  const beforeCss = css`
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  `;

  if (isFile(background)) {
    return css`
      ${before ? '::before' : '&'} {
        background: ${`url(${URL.createObjectURL(background)})`};
        background-size: cover;
        ${before && beforeCss}
      }
    `;
  }
  const url = getResourcePath(background);
  return css`
    ${before ? '::before' : '&'} {
      background: ${isCSSRule(background) ? background : `url(${url})`};
      background-size: cover;
      ${before && beforeCss}
    }
  `;
};
